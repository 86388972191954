import React from "react";
import Hero from "src/components/Modules/Hero";
import PageStyles from "src/components/styles/PageStyles";

import SEO from "src/components/Modules/SEO";
import Flex from "src/components/UI/Structure/Flex";
import Text from "src/components/UI/Text";

const ErrorTemplate = ({ title, buttonText }) => {
  return (
    <PageStyles>
      <SEO title={title} />
      <Hero
        headerColor="dark"
        title={title}
        link="/"
        linkTitle={buttonText ? buttonText : null}
        buttonLayout={
          buttonText
            ? {
                filled: "dark",
                defaultStyle: 0,
              }
            : null
        }
        buttonContent={
          buttonText ? (
            <Flex width={1} alignItems="center">
              <Text fontSize={2} fontWeight="bold" letterSpacing="0.75px">
                {buttonText}
              </Text>
              <Text fontSize={2} ml="auto" pl="5" fontWeight="bold">
                →
              </Text>
            </Flex>
          ) : null
        }
        images={null}
      />
    </PageStyles>
  );
};

export default ErrorTemplate;
