import React from "react";
import { StaticQuery, graphql } from "gatsby";

import HomeTemplate from "src/components/PageTemplates/PageTemplate/Templates/HomeTemplate";
import ErrorTemplate from "src/components/PageTemplates/ErrorTemplate/ErrorTemplate";

const IndexPage = ({ data }) => {
  // Get home page
  let page =
    data?.allWpPage?.edges?.length > 0
      ? data.allWpPage.edges.filter((page) => {
          return page.node.template.templateName === "Home";
        })[0]
      : null;

  if (page?.node) {
    return <HomeTemplate page={page.node} />;
  } else {
    return (
      <ErrorTemplate
        title="Sidan du försöker nå kunde inte hittas"
        buttonText="Till startsidan"
      />
    );
  }
};

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allWpPage {
          edges {
            node {
              id
              link
              content
              title
              template {
                templateName
              }
              acfHomePage {
                title
                slide {
                  image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                          placeholder: DOMINANT_COLOR
                        )
                      }
                    }
                  }
                  label
                }
              }
              acfPageSeo {
                seoTitle
                seoDescription
                seoImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FIXED, width: 1200, height: 628)
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <IndexPage data={data} {...props} />}
  />
);
